<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import AccountProfileGrid from '@/components/account/AccountProfileGrid'
  import HomeMemberProfile from '@/components/home/HomeMemberProfile'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import { ApiError } from '@/api'

  // Remove feature flag check once member dashboard is live
  const guardMixin = createGuardMixin(
    async ({ store, redirect }) => {
      const isLoggedIn = await store.dispatch('account/loggedInAsync')
      if (!isLoggedIn) {
        return redirect({ name: 'AccountLogin' })
      }
    },
    ['isLoggedIn']
  )

  export default {
    components: {
      AccountProfileGrid,
      HomeMemberProfile,
    },
    mixins: [guardMixin],
    data() {
      return {
        tabs: [
          {
            to: '/account/settings',
            label: 'Settings',
          },
          {
            to: '/account/subscriptions',
            label: 'Memberships',
          },
          {
            to: '/account/orders',
            label: 'Orders',
          },
        ],
      }
    },
    computed: {
      ...get(['route']),
      ...get('feature', ['flags']),
      ...get('general', ['isUnsubscribeAction', 'isReactivateAction']),
      screen,
      isRouteNested() {
        return [
          'AccountProfileChangeSubscriptionAddress',
          'AccountProfileSettingsAddAddress',
          'AccountProfileSettingsChangePassword',
          'AccountProfileSettingsCreditCard',
          'AccountProfileSettingsEditAddress',
          'AccountProfileSettingsEditInfo',
          'AccountProfileSettingsPaymentMethod',
          'AccountProfileSettingsRemoveAddress',
          'AccountSubscriptionEdit',
        ].includes(this.route.name)
      },
      isFullScreenOnMobile() {
        return ['AccountProfileOrdersAll', 'AccountAddOn'].includes(this.route.name)
      },
      routeComponent() {
        return this.$router.getMatchedComponents(this.route).pop()
      },
      bgColor() {
        return !this.screen.md ? 'bg-dawn' : ''
      },
    },
    async created() {
      try {
        await this.$store.dispatch('account/fetchProfile')
      } catch (error) {
        if (error instanceof ApiError) {
          return
        }
        return Promise.reject(error)
      }
    },
  }
</script>

<template>
  <section>
    <div :class="`${isFullScreenOnMobile ? '' : 'min-h-screen overflow-hidden'} ${bgColor}`">
      <BaseTransitionView appear>
        <div v-if="isRouteNested && !screen.md" key="nested" class="pt-6 pb-8">
          <BaseContainer>
            <BaseTransitionView :class="bgColor">
              <component
                :is="routeComponent"
                :clicked-action="
                  route.name === 'AccountSubscriptionEdit' ? $route.params.clickedAction : undefined
                "
                :selected-subscription-id="
                  route.name === 'AccountSubscriptionEdit'
                    ? Number($route.params.selectedSubscriptionId)
                    : undefined
                "
                :subscription-type="
                  route.name === 'AccountSubscriptionEdit'
                    ? $route.params.subscriptionType
                    : undefined
                "
              />
            </BaseTransitionView>
          </BaseContainer>
        </div>
        <div v-else-if="isFullScreenOnMobile && !screen.md">
          <component
            :is="routeComponent"
            :subscription-id="
              route.name === 'AccountAddOn' ? Number($route.params.subscriptionId) : undefined
            "
            :type="route.name === 'AccountAddOn' ? $route.params.type : undefined"
          />
        </div>
        <div v-else key="root">
          <!-- TODO: replace placeholder banner and boxes -->
          <component :is="screen.md ? 'BaseContainer' : 'div'" max-width="2xl">
            <AccountProfileGrid class="md:pt-6">
              <div class="md:pb-8">
                <div class="mb-4 bg-white shadow-gray-border">
                  <HomeMemberProfile account-view />
                  <BaseDivider v-if="screen.md" />
                  <BaseTabs :tabs="tabs" />
                </div>
                <BaseTransitionView>
                  <RouterView />
                </BaseTransitionView>
              </div>
              <div class="flex flex-col">
                <BaseLink
                  class="mb-4"
                  href="https://shop.alltrue.com/refer"
                  :style="{ height: $pxToRem(290) }"
                >
                  <BaseBanner size="medium-full" class="bg-dawn-lt2">
                    <div v-if="screen.lg" class="flex justify-center">
                      <BaseImage
                        src="/static/InvitationSummer.png"
                        alt=""
                        background-transparent
                        responsive="xs"
                        class="w-34 pb-2"
                      />
                    </div>
                    Invite Friends, Get $15
                    <template v-slot:description>
                      Share your code with friends and get $15 in Alltrue credit.
                    </template>
                    <template v-slot:cta>Invite</template>
                  </BaseBanner>
                </BaseLink>
                <BaseLink href="https://alltrue.com/gift" class="mb-4">
                  <BaseBanner size="medium-half" class="bg-spring">
                    Gift a Box
                    <template v-slot:description>
                      Send an Alltrue gift card. A perfect gift for anyone!
                    </template>
                    <template v-slot:cta>Buy</template>
                  </BaseBanner>
                </BaseLink>
              </div>
            </AccountProfileGrid>
          </component>
        </div>
      </BaseTransitionView>
    </div>
  </section>
</template>
