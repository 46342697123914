import { render, staticRenderFns } from "./AccountProfileGrid.vue?vue&type=template&id=c5c1d630&scoped=true&"
var script = {}
import style0 from "./AccountProfileGrid.vue?vue&type=style&index=0&id=c5c1d630&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5c1d630",
  null
  
)

export default component.exports